<template>
  <div>
    <p class="p3 color-green-2 ml-2 mb-4">
      Atualize as informações do evento, adicionando ou removendo participantes conforme 
      necessário para garantir um registro completo e preciso da participação nas assembleias.
    </p>
    <b-card border-variant="light" class="custom-card">
      <b-form id="alter-form" class="alter-form">
        <b-form-row>
          <b-col>
            Evento
          </b-col>
          <b-col>
            <b-form-select
              v-model.lazy.trim="form.name"
              :options="assemblyTitleOptions"
              :state="validateField('name')"
            />
          </b-col>
        </b-form-row>
        <b-form-row>
          <b-col>
            Data
          </b-col>
          <b-col>
            <date-picker
              datePickerId="date"
              :key="form.date"
              :dataValue="form.date"
              @datePickerChangedValue="onChangedDate"
              :required="true"
              :state="validateFieldStartDate('date')"
            />
          </b-col>
        </b-form-row>
        <b-form-row>
          <b-col>
            INFORMAR&nbsp;CRM(S)&nbsp;PARTICIPANTES 
          </b-col>
          <b-col>
            <b-form-tags
              input-id="tags-basic"
              placeholder="Adicione o(s) CRM(s)"
              v-model="form.participants"
            ></b-form-tags>
          </b-col>
        </b-form-row>
        <b-form-row >
          <b-col>
            REMOVER&nbsp;CRM(S)&nbsp;PARTICIPANTES 
          </b-col>
          <b-col>
            <b-form-tags
              input-id="tags-basic"
              placeholder="Adicione o(s) CRM(s)"
              v-model="form.deleteParticipants"
            ></b-form-tags>
          </b-col>
        </b-form-row>
        <b-form-row>
          <b-col>
            <UploadFile
              :key="formFileId"
              :multiple="false"
              :accept="
                'application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
              "
              @updateFile="eventToUpdateFile"
              :description="'Importar arquivo'"
              v-model.lazy.trim="formFile"
            >
            </UploadFile>
          </b-col>
        </b-form-row>
      </b-form>
    </b-card>
    <b-button
      :disabled="loading"
      type="button"
      class="mt-3"
      variant="primary"
      @click="onSubmit"
    >
      Salvar
      <i class="fas fa-spinner fa-spin icon" v-show="this.loading"></i>
    </b-button>
    <report-success-modal
      description="Evento editado com sucesso!"
      modalId="file-attention-modal"
      submitButtonText="Baixar relatório"
      :onAction="onActionEditConfirmation"
      ref="file-attention-modal"
    >
      <template slot="description">
        <div class="justify-content-center text-center w-100">
          <p class="p1 mb-5 confirm-text">
            Evento editado com sucesso!
          </p>
        </div>
      </template>
    </report-success-modal>
    <attention-modal
      modalId="validate-crm-attention-modal"
      title="Atenção!"
      submitButtonText="Ok, entendi!"
      ref="attention-modal"
      :onAction="this.onAttentionConfirm"
    >
      <template slot="description">
        <div class="justify-content-center text-center w-100">
          <p class="p1 mb-5 confirm-text">
            {{ alertValidationMessage }}
          </p>
        </div>
      </template>
    </attention-modal>
  </div>
</template>
<script>
import DatePicker from "../../../../components/date-picker/DatePicker.vue";
import AttentionModal from "../../../../components/base-modals/AttentionModal.vue";
import ReportSuccessModal from "../../../../components/base-modals/ReportSuccessModal.vue";
import UploadFile from "../../../../components/upload-file/UploadFile.vue";
import { getAssemblyById, getAssemblyReport, updateAssembly } from "../../../../services/assembly-management/assembly-management-service";
import {
  validateField,
  validateFieldStartDate,
} from "../../../../utils/validate/validate.js";
import { inject } from "@vue/composition-api";
import { createToast } from "../../../../components/toast/toast";
import { anchorBase64Download } from "../../../../utils/anchor-base64-download/anchor-base64-download";
import RoutesPaths from "../../../../router/router-structure/routes-paths";
import router from "../../../../router";

const DefaultForm = {
  id: null,
  name: "",
  date: null,
  participants: null,
  deleteParticipants: null,
};

export default {
  components: {
    ReportSuccessModal,
    AttentionModal,
    DatePicker,
    UploadFile,
  },
  data() {
    return {
      form: { ...DefaultForm },
      formFile: [],
      formFileId: 0,
      validations: {},
      loading: false,
      assemblyTitleOptions: [
        {
          value: "",
          text: "Selecione",
        },
        {
          value: "AGO - Assembleia Geral Ordinária",
          text: "AGO - Assembleia Geral Ordinária",
        },
        {
          value: "AGE - Assembleia Geral Extraordinária",
          text: "AGE - Assembleia Geral Extraordinária",
        },
      ],
      validateField,
      validateFieldStartDate,
      formSubmited: false,
      alertValidationMessage: "",
    };
  },
  setup() {
    const globalLoading = inject("globalLoading");
    return { globalLoading };
  },
  async mounted() {
    this.globalLoading = true;
    try {
      if (this.$route.params.id) {
        const assembly = await getAssemblyById(this.$route.params.id);
        this.form = { ...assembly };
      }
    } catch (error) {
      this.globalLoading = false;
      console.error("mounted", error);
      createToast("Atenção", "Ocorreu um erro carregar as informações do evento.");
    }
    
    this.globalLoading = false;
  },
  methods: {
    async onSubmit() {
      this.formSubmited = true;
      var canSubmit = this.validateForm();

      if (canSubmit) {
        this.loading = true;

        try {
          await updateAssembly(this.form, this.formFile);
          const modal = this.$refs["file-attention-modal"].$children[0];
          modal.show();
        } catch (e) {
          if (
              e.response &&
              e.response.data &&
              e.response.data.error_code &&
              (
                e.response.data.error_code == "ASSEMBLY_NOT_FOUND" ||
                e.response.data.error_code == "EXISTING_ASSEMBLY"
              )
            ) {
              this.showValidationAlert(e.response.data.message);
          } else {
            console.error("onSubmit", e);
            createToast("Atenção", "Ocorreu um erro atualizar as informações do evento.");
          }
          console.log(e.response.data.message);
        } finally {
          this.formSubmited = false;
          this.loading = false;
        }
      }
    },
    async onReportParticipationClick() {
      const reportResponse = await getAssemblyReport(this.$route.params.id);

      anchorBase64Download(
        null,
        "application/xls",
        this.form.name + " - " + this.form.date + ".xlsx",
        reportResponse.fileBase64
      );
    },
    async onActionEditConfirmation(action){
      if (action) {
        await this.onReportParticipationClick();
      } else {
        router.push({ path: RoutesPaths.contentManagement.assemblyManagement(), replace: true });
      }
    },
    eventToUpdateFile(file) {
      this.formFile = file;
    },
    onChangedDate(value) {
      this.form.date = value;
    },
    validateForm() {
      return this.requiredFieldsFilled();
    },
    showValidationAlert(message) {
      this.alertValidationMessage = message;
      const modal = this.$refs["attention-modal"].$children[0];
      modal.show();
    },
    validateCrmFields() {
      return this.form.participants.length > 0 || this.formFile.length > 0;
    },
    requiredFieldsFilled() {
      return Object.values(this.validations).every(
        (fieldValue) => fieldValue == true
      );
    },
    onAttentionConfirm() {
      const modal = this.$refs["attention-modal"].$children[0];
      modal.hide();
    },
  },
};
</script>
