<template>
  <b-modal :id="lModalId" ref="attention-modal" ok-only>
    <b-container>
      <template #modal-header> </template>

      <b-row class="justify-content-center">
        <div class="success-container">
          <div class="success-container--outter-circle">
            <div class="success-container--inner-circle">
              <i class="success-container--icon fas fa-check"></i>
            </div>
          </div>
        </div>
      </b-row>
      <b-row class="justify-content-center text-center my-5">
        <h2 class="font-weight-bold m-0">
          Sucesso!
        </h2>
      </b-row>
      <b-row class="justify-content-center text-center my-5">
        <p class="p1">
          {{ this.$props.description }}
        </p>
      </b-row>
      <b-row class="justify-content-center text-center my-5">
        <p class="p1" v-if="this.$props.subdescription">
          {{ this.$props.subdescription }}
        </p>
        <slot name="subdescription" v-else> </slot>
      </b-row>
    </b-container>
    <template #modal-footer>
      <div class="w-100 d-flex align-items-center justify-content-center">
        <b-button @click="onActioHandler(true)" variant="outline-primary">
          {{ $props.submitButtonText ? $props.submitButtonText : "Voltar" }}
        </b-button>

        <b-button @click="onActioHandler(false)" class="ml-4" variant="primary">
          {{ $props.cancelButtonText ? $props.cancelButtonText : "Confirmar" }}
        </b-button>
      </div>
    </template>
  </b-modal>
</template>

<script>

import resolve_img_url from "../../utils/image/image-utils";

export default {
  props: [
    "title",
    "modalId",
    "description",
    "buttonText",
    "cancelButtonText",
    "submitButtonText",
    "onAction",
  ],
  setup(props, ctx) {
    const lModalId = props.modalId ? props.modalId : "send-modal";

    const closeModal = function() {
      ctx.refs["attention-modal"].hide();
    };

    const onActioHandler = function(event) {
      props.onAction(event);
      closeModal();
    };

    return { lModalId, onActioHandler };
  },
  methods: {
    resolve_img_url: resolve_img_url,
  },
};
</script>

<style lang="scss" scoped>
.img-exclamation {
  width: 20px;
  height: 20px;
}
.success-container {
  &--icon {
    font-size: 4rem;
    color: white;
  }

  &--inner-circle {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 7rem;
    width: 7rem;
    background: var(--primary);
    border-radius: 100%;
  }

  &--outter-circle {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 9rem;
    width: 9rem;
    background: var(--green-1);
    border-radius: 100%;
  }
}
.font-size-custom {
  font-size: 1.6rem;
  margin-left: 1rem;
}
.custom-align-button {
  margin-left: auto !important;
  margin-right: auto;
}
</style>
